import action from './action';
import data_type from './data_type';
import db_history from './db_history';
import ingestion_limit from './ingestion_limit';
import registry from './registry';
import sf_asset from './sf_asset';
import sf_table from './sf_table';
import {UserRoleEnum} from '@mts/user-core';
import { PlaybackInspectorPlugin } from '@mts/reports-playback_inspector-react';
import { ForkOutlined, BarChartOutlined, FileAddOutlined } from '@ant-design/icons';

const infrastructure = {
  type: 'group',
  title: 'Infrastructure',
  roles: UserRoleEnum,
  children: {
    action,
    action_links: {
      title: 'Action Links',
      key: 'action_links',
      icon: <ForkOutlined />,
      type: 'action_links',
      roles: ['Public']
    },
    data_type,
    ingestion_limit,
    db_history,
    registry,
    sf_asset,
    sf_table,
    playback_inspector: {
      title: PlaybackInspectorPlugin.menu.label,
      key: PlaybackInspectorPlugin.menu.key,
      icon: PlaybackInspectorPlugin.menu.icon,
      type: 'playback_inspector',
      roles: PlaybackInspectorPlugin.menu.roles
    },
    control_records: {
      title: 'Control Records',
      key: 'control_records',
      icon: <BarChartOutlined />,
      type: 'control_records',
      roles: ['Admin']
    },
    playback_control_records: {
      title: 'Playback Control Records',
      key: 'playback_control_records',
      icon: <BarChartOutlined />,
      type: 'playback_control_records',
      roles: ['Admin']
    },
    file_upload: {
      title: 'File Upload',
      key: 'file_upload',
      icon: <FileAddOutlined />,
      type: 'file_upload',
      roles: ['Admin']
    }
  }
};

export default infrastructure;
