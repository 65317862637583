import React, { Component } from 'react';
import { Breadcrumb } from 'antd';

class Breadcrumbs extends Component {

  render() {
    const crumbs = [];

    crumbs.push(<Breadcrumb.Item key={-1}>KMAP MTS Data Ingester {process.env.REACT_APP_VERSION}</Breadcrumb.Item>);

    const r = (obj, pos = 0) => {
      const c = obj[this.props.activeArr[pos]];
      crumbs.push(<Breadcrumb.Item key={pos}>{c.title}</Breadcrumb.Item>);
      if (pos === this.props.activeArr.length - 1) return;
      if (typeof c.children === 'undefined') return;
      r(c.children,pos + 1);
    };

    r(this.props.content);

    return (
      <Breadcrumb>
        {crumbs}
      </Breadcrumb>
    );
  }

}

export default Breadcrumbs;
