import all_jobs from './all_jobs';
import active_jobs from './active_jobs';
import job_runner from './job_runner';
import { UserRoleEnum } from '@mts/user-core';

const job = {
  type: 'group',
  title: 'Jobs',
  roles: UserRoleEnum,
  children: {
    job_runner,
    active_jobs,
    all_jobs
  }
};

export default job;
