import {useEffect, useState, useRef, useLayoutEffect, useContext} from 'react'; //, useContext, useRef, useLayoutEffect} from 'react';
import { Spin, Col, DatePicker, Row, Divider, Select } from 'antd';
import axios from 'axios';
import * as d3 from 'd3';
import { ContentContext } from '../App';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export const margin = {top: 50, right: 20, bottom: 10, left: 130};

export const tileGap = 2;

export const legendHeight = 30;

export const data_states = [
  'Queued',
  'Retry Wait',
  'Start Pending',
  'Running',
  'Failed',
  'Canceled',
  'Complete'
];
export const colors = [
  d3.color('cornsilk'),
  d3.color('hotpink'),
  d3.color('white'),
  d3.color('turquoise'),
  d3.color('red'),
  d3.color('darkgrey'),
  d3.color('green')
];

export function convert_name(name) {
  switch (name) {
    case 'Receive DAO File': return 'Receive';
    case 'Ingest DAO v1 File': return 'Ingest v1';
    case 'Ingest DAO v2 File': return 'Ingest v2';
    case 'Calculate Lat/Long for DAO v1 File': return 'Lat/Long';
    case 'Manually Materialized Views': return 'MMV';
    case 'MODULAR MMV': return 'MODULAR';
    case 'FRONTRUNNER MMV': return 'FRONTRUNNER';
    case 'Parse DISPATCH Transaction Logs': return 'Parse';
    case 'Parse DISPATCH Exception Logs': return 'Parse';
    default:
      return name;
  }
}


//const offset = new Date().getTimezoneOffset();

class chart_class {
  constructor(width, height, week, data_types) {
    this.loading = false;
    this.week = week;
    this.svg = d3.create('svg')
      .attr('viewBox', [0, 0, width, height]);

    const y_values = data_types.reduce((acc, cur) => {
      for (const a of cur.actions) {
        acc.push(convert_name(cur.name) + ' ' + convert_name(a));
      }
      return acc;
    }, []);

    console.assert(y_values.length > 0);
    
    this.legendG = this.svg.append('g');
    this.l = d3.scaleBand().domain(data_states).range([10, width - 10]).padding(0.1);
    this.legendAxis = this.legendG.append('g').attr('transform', `translate(0, ${legendHeight - 5})`).call(d3.axisBottom(this.l));
    this.legendRect = this.legendG.append('g').selectAll('rect').data(data_states).enter().append('rect')
      .attr('x', d => this.l(d))
      .attr('y', 5)
      .attr('width', this.l.bandwidth())
      .attr('height', legendHeight - 5)
      .attr('fill', d => colors[data_states.findIndex(s => s === d)]);
    this.legendAxis.select('.domain').remove();
    this.legendAxis.selectAll('.tick').select('line').remove();
    

    this.xAxisG = this.svg.append('g').attr('transform', `translate(0,${margin.top + legendHeight})`);
    this.yAxisG = this.svg.append('g').attr('transform', `translate(${margin.left}, 0)`);
    this.rectsG = this.svg.append('g');
    

    this.x = d3.scaleUtc().range([margin.left, width - margin.right]);
    this.y = d3.scaleBand()
      .domain(y_values)
      .rangeRound([margin.top + legendHeight, height - margin.bottom])
      .padding(0.1);
    this.c = d3.scaleOrdinal().domain(data_states).range(colors).unknown('yellow');
  }
  loadData(props) {
    if (this.loading) return;
    this.loading = true;
    axios.post('/reports/control_records/controls', { week: props.week.toISOString(), assets: props.asset_group.assets })
      .then(res => {
        console.log('data', res.data);
        this.update(res.data);
      })
      .catch(err => {
        console.log('error', err);
      })
      .finally(() => {
        this.loading = false;
        // TODO run timeout here
      });
  }

  update(data) {
    const prep = data.reduce((acc, cur) => {
      cur.data_date = moment(cur.data_date);
      cur.end_date = moment(cur.end_date);
      if (acc.x.min === null || cur.data_date < acc.x.min) acc.x.min = cur.data_date;
      if (acc.x.max === null || cur.end_date > acc.x.max) acc.x.max = cur.end_date;
      for (const j of cur.jobs) {
        acc.data.push({
          id: j._id,
          data_date: cur.data_date,
          end_date: cur.end_date,
          status: j.state,
          name: convert_name(cur.data_type.name) + ' ' + convert_name(j.action.name)
        });
      }
      return acc;
    }, {
      x: {
        min: moment(this.week).startOf('week'),
        max: moment(this.week).endOf('week')
      },
      data: []
    });
    console.log('data', prep.data);

    this.x.domain([prep.x.min, prep.x.max]);

    const transition = this.svg.transition().duration(500).ease(d3.easeLinear);

    this.xAxisG.transition(transition).call(d3.axisTop(this.x));
    this.yAxisG.transition(transition).call(d3.axisLeft(this.y));

    const whole = this.y.bandwidth();
    //const half = whole / 2;

    this.rectsG.selectAll('rect')
      .data(prep.data, d => d.id)
      .join(
        enter => enter
          .append('rect')
          .attr('x', d => this.x(d.data_date) + tileGap /2)
          .attr('y', d => this.y(d.name))
          .attr('width', d => this.x(d.end_date) - this.x(d.data_date) - tileGap)
          .attr('height', () => whole)
          .attr('fill', d => this.c(d.status)),
        update => update
          .transition(transition)
          .attr('x', d => this.x(d.data_date) + tileGap /2)
          .attr('y', d => this.y(d.name))
          .attr('width', d => this.x(d.end_date) - this.x(d.data_date) - tileGap)
          .attr('height', () => whole)
          .attr('fill', d => this.c(d.status)),
        exit => exit.transition(transition).remove()
      );
  }
}

function Chart(props) {
  const ref = useRef(null);
  const chart = new chart_class(props.width, props.height, props.week, props.data_types);

  useLayoutEffect(() => {
    ref.current.replaceChildren();
    ref.current.appendChild(chart.svg.node());
    chart.loadData(props);
  }, [props, ref]);

  return <div key='chart' ref={ref} style={{ width: props.width, height: props.height}}/>;
}

function AssetGroupDisplay(props) {
  const content = useContext(ContentContext);

  const height = content.contentOffsets.height - 25 - 24 - 1 - 25 - 24 - 24;
  const width = content.contentOffsets.width - 24 - 24;

  return (
    <>
      {props.asset_group === null
        ? <div>Please Select Asset</div> 
        : props.week === null
          ? <div>Please Select Week (TODO: make current week default)</div>
          : <Chart asset_group={props.asset_group} width={width} height={height} week={props.week} data_types={props.data_types}/>}
    </>
  );

}

export function ControlRecords(props) {
  const nav = useNavigate();
  const asset_group = props.extra_path.at(0) ? decodeURIComponent(props.extra_path[0]) : null;
  const week = props.extra_path.at(1) ? moment.utc(decodeURIComponent(props.extra_path[1])) : null;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [asset_groups, setAssetGroups] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get('/reports/control_records/asset_groups')
      .then(res => {
        setError(null);
        setAssetGroups(res.data);
      })
      .catch(err => {
        setAssetGroups([]);
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const [loading_dt, setLoadingDt] = useState(true);
  const [error_dt, setErrorDt] = useState(null);
  const [data_types, setDataTypes] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get('/reports/control_records/data_types')
      .then(res => {
        setErrorDt(null);
        console.log('received data_types', res.data);
        setDataTypes(res.data);
      })
      .catch(err => {
        setDataTypes([]);
        setErrorDt(err);
      })
      .finally(() => {
        setLoadingDt(false);
      });
  }, []);

  const onChangeAG = (value) => {
    nav('/infrastructure/control_records/' + value + (week === null ? '' : '/' + week.toISOString()));
    //setPos(e.target.value);
  };

  const onChangeWeek = (date_str) => {
    const date = moment.utc(date_str);
    //const offset = date.getTimezoneOffset();
    //const new_date = new Date(date.getTime() - (offset * 60 * 1000));
    const midnight = date.startOf('week').toISOString(); //new Date(new_date.toISOString().split('T')[0] + 'T00:00:00.000Z').toISOString();
    nav('/infrastructure/control_records/' + asset_group +  '/' + midnight);
  };

  return (
    <>
      <Row justify='space-evenly' align='middle' style={{ height: 25}}>
        <Col>
          {
            loading || loading_dt
              ? <Spin />
              : error || error_dt
                ? <div>{JSON.stringify(error || error_dt)}</div>
                : <Select style={{width: 300}} showSearch onChange={onChangeAG} defaultValue={asset_group} placeholder="Select Asset" options={asset_groups.map((a) => ({ value: a.name, label: a.name }))} />

          }
        </Col>
        <Col>
          <DatePicker onChange={onChangeWeek} picker='week' disabled={asset_group === null} defaultValue={week === null ? undefined : week} />
        </Col>
      </Row>
      <Divider />
      <Row align='top'>
        <AssetGroupDisplay asset_group={asset_groups.find(a => a.name === asset_group) || null} week={week} data_types={data_types}/>
      </Row>
    </>
  );
}

