import React from 'react';
import { gql } from '@apollo/client';
import { FileDoneOutlined } from '@ant-design/icons';
import { UserRoleEnum } from '@mts/user-core';
import filterDateRange from '../components/FilterableDateRange';
import filterable from '../components/Filterable';
import filterableRegex from '../components/FilterableRegex';

const filterQuery = gql`
query ControlFilters($column: String) {
  controlFilters(column: $column) {
    type,
    filters { key, value }
  }
}
`;
const filterIndex = 'controlFilters';

const control = {
  title: 'Controls',
  icon: <FileDoneOutlined />,
  roles: UserRoleEnum,
  type: 'table',
  data: {
    query: gql`
      query ControlPagination($current:Int!,$pageSize:Int!,$filters:ControlFilters,$sorters:Sorters) {
        controlPagination(current:$current,pageSize:$pageSize,filters:$filters,sorters:$sorters) {
          current
          pageSize
          total
          data {
            _id name asset_id {asset_id} data_type { name source } precursor { name status mtime } shiftid data_date end_date mtime status 
          }
        }
      }
    `,
    nData: 'controlPagination',
    columns: [
      {title: 'Name', dataIndex: 'name', ...filterableRegex()},
      {title: 'Asset Id', dataIndex: 'asset_id', render: d => d ? d.asset_id : 'null', ...filterable(filterQuery, filterIndex, 'asset_id')},
      {title: 'Data Source', dataIndex: 'data_type', render: d => d.source, ...filterable(filterQuery, filterIndex, 'data_type_source')},
      {title: 'Data Type', dataIndex: 'data_type', render: d => d.name, ...filterable(filterQuery, filterIndex, 'data_type')},
      {title: 'Precursor', dataIndex: 'precursor', render: d => d == null ? '' : `${d.name} (${d.status}) @ ${d.mtime}`},
      {title: 'Shift Id', dataIndex: 'shiftid', sorter: true}, 
      {title: 'Data Date', dataIndex: 'data_date', ...filterDateRange()},
      {title: 'End Date', dataIndex: 'end_date', ...filterDateRange()},
      {title: 'Modification Time', dataIndex: 'mtime', ...filterDateRange()},
      {title: 'Status', dataIndex: 'status', render: s => s.replace(/_/g, ' '), ...filterable(filterQuery, filterIndex, 'status')}
    ]
  },
  pollInterval: 5000
};

export default control;
