import reportWebVitals from './reportWebVitals';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { UserPlugin } from '@mts/user-react';
import client from './components/Client';
import './index.less';
import { App } from './App';

const container = document.getElementById('root');
if (container === null) throw new Error('index.html must have element with id=root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <UserPlugin.provider>
          <Routes>
            <Route exact path="/" element={<Navigate replace to="/log"/>} />
            <Route path='/:active?' element={<App /> } />
            <Route path='/:active/*' element={<App /> } />
          </Routes>
        </UserPlugin.provider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
