import React, { useContext, useState, useRef } from 'react';
import { Layout, Menu, Divider, Row } from 'antd';
import { UserContext } from '@mts/user-react';

function ContentSider(props) {
  const ref = useRef(null);
  const user_context = useContext(UserContext);
  const [openKeys, setOpenKeys] = useState([props.active.split('/')[0]]);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (Object.keys(props.content).indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const rolesIntersect = (roles) => {
    if (typeof roles === 'undefined' || roles.length === 0) return true;
    for (const role of roles) {
      if (!user_context.value.user.roles.includes(role)) return false;
    }
    return true;
  };

  const buildMenu = (obj, prefix) => {
    const content = [];
    for (const key of Object.keys(obj)) {
      if (!rolesIntersect(obj[key].roles)) continue;
      if (obj[key].type === 'group') {
        content.push({
          label: obj[key].title,
          key: prefix + key,
          icon: obj[key].icon,
          children: obj[key].children ? buildMenu(obj[key].children, prefix + key + '/') : undefined
        });
      } else {
        content.push({
          label: obj[key].title,
          key: prefix + key,
          icon: obj[key].icon
        });
      }
    }
    return content;
  };

  return (
    <Layout.Sider theme="dark" width={322} ref={ref} >
      <Row>
        <div className='logo'>
          <img className='logo' src={`${window.location.origin.toString()}/Komatsu-Logo-White.png`} alt='Komatsu Logo' />
        </div>
      </Row>
      <Row>
        <Divider />
      </Row>
      <Row style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: ref.current ? ref.current.getBoundingClientRect().height - 104 - 48 : '100%' }}>
        <Menu
          mode="inline"
          className="menu"
          selectedKeys={[props.active]}
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          onClick={(e) => {
            console.log('set active', e.key);
            props.setActive(e.key);
          }}
          items={buildMenu(props.content, '')}
        />
      </Row>

    </Layout.Sider>
  );
}

export default ContentSider;
