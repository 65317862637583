import React from 'react';
import moment from 'moment';
import { Row, Button, DatePicker } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function() { //filterQuery, filterIndex, dataIndex) {
  return {
    // eslint-disable-next-line no-unused-vars
    filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
      const value = [];
      try {
        const temp = JSON.parse(selectedKeys);
        value.push(...temp.value.map(v => moment.utc(v)));
      } catch (err) {
        // ignore error
      }
      //console.log('value', value);
      return (
        <div style={{ padding: 8 }}>
          <Row>
            <DatePicker.RangePicker
              value={value}
              onChange={(_dates, dateStrings) => setSelectedKeys(JSON.stringify({ type: 'dateRange', value: dateStrings.map(d => moment.utc(d)) }))}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                Reset
            </Button>
          </Row>
        </div>
      );
    },
    filterIcon(filtered) { return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />; },
    filterMultiple: false,
    sorter: true
  };
}