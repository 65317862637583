import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, message } from 'antd';
import { NetworkStatus, useQuery } from '@apollo/client';
import { UserContext } from '@mts/user-react';
import hasProp from '../helpers/hasProp';
import { EditableCell, EditableRow } from './EditableTableExtensions';
import { debounce } from 'debounce';

function useQueryModified(query, variables) {
  const [ filledData, setFilledData ] = useState(undefined);
  const [ filledLoading, setFilledLoading ] = useState(true);
  
  const { data, loading, fetchMore, startPolling, stopPolling, networkStatus} = useQuery(query, {
    variables,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data && !loading) {
      setFilledData(data);
    }
    if (!loading) {
      setFilledLoading(false);
    }
  }, [data, loading]);

  useEffect(() => {
    if (networkStatus === NetworkStatus.loading) {
      setFilledLoading(true);
    } else {
      setFilledLoading(false);
    }
  }, [networkStatus]);


  return { data: filledData, loading: filledLoading, fetchMore, startPolling, stopPolling, networkStatus };
}

function DataTable(props) {
  const user_context = useContext(UserContext);

  const initData = {
    current: 1,
    pageSize: props.pageSize,
    // TODO: figure out default on first load from columns
    filters: {},
    sorters: {}
  };

  const [variables, setVariables] = useState(initData);

  const { data, loading, fetchMore, startPolling, stopPolling, networkStatus } = useQueryModified(props.table.query, variables);

  useEffect(() => {
    console.log('pollInterval', props.pollInterval);
    if (props.pollInterval) {
      console.log('polling');
      startPolling(props.pollInterval);
      return () => {
        stopPolling();
      };
    }
  }, []);

  const refresh = debounce(() => {
    fetchMore({
      variables,
      updateQuery: (prev, {fetchMoreResult}) => {
        if (!fetchMoreResult) return prev;
        message.info('refreshed');
        return fetchMoreResult;
      }
    });
  }, 2000, true);

  useEffect(() => {
    refresh();
  }, [variables]);

  const handleChange = (pagination,filters,sorters) => {
    initData.filters = Object.keys(filters).reduce((acc,cur) => { if (filters[cur] !== null) acc[cur] = filters[cur]; return acc;},{});
    initData.sorters = {
      field: sorters.field,
      order: sorters.order
    };
    setVariables({
      current: pagination.current,
      pageSize: pagination.pageSize,
      filters: initData.filters,
      sorters: initData.sorters
    });
  };

  const rowChanged = (variables) => {
    const pos = data[props.table.nData].data.findIndex(d => d._id === variables._id);
    if (pos === -1) return false;
    let changed = false;
    for (const key of Object.keys(variables)) {
      if (variables[key] !== data[props.table.nData].data[pos][key]) {
        changed = true;
        break;
      }
    }
    return changed;
  };

  const columns = typeof props.table.columns === 'function' ? props.table.columns(user_context.value.user) : props.table.columns;

  return (
    <>
      <Button onClick={refresh} loading={networkStatus !== NetworkStatus.ready}>{networkStatus !== NetworkStatus.ready ? 'Refreshing' : 'Refresh'}</Button>
      <Table
        bordered={props.table.bordered}
        columns={columns.map(col => props.table.editableRows && col.editable ? 
          {
            ...col,
            onCell: (record) => ({
              record,
              editable: col.editable(record),
              dataIndex: col.dataIndex,
              title: col.title,
              editableRows: props.table.editableRows,
              rowChanged
            })
          } : 
          col
        )}
        rowKey={d => d._id}
        dataSource={!loading && data && data[props.table.nData] ? data[props.table.nData].data : []}
        pagination={{
          current: !loading && data && data[props.table.nData] ? data[props.table.nData].current : initData.current,
          pageSize: !loading && data && data[props.table.nData] ? data[props.table.nData].pageSize : initData.pageSize,
          total: !loading && data && data[props.table.nData] ? data[props.table.nData].total : 0
        }}
        loading={networkStatus !== NetworkStatus.ready}
        onChange={handleChange}
        expandable={{
          rowExpandable: hasProp(props.table,'rowExpandable') ? props.table.rowExpandable : () => false,
          expandedRowRender: hasProp(props.table,'expandedRowRender') ? props.table.expandedRowRender : () => (<></>)
        }}
        //editable extensions
        rowClassName={() => props.table.editableRows ? 'editable-row' : 'noneditable-row'}
        components={props.table.editableRows
          ? {
            body: {
              row: EditableRow,
              cell: EditableCell
            }
          }
          : {}
        }
      />
    </>
  );
}

/* subscriptions stuff
class SubscriptionWrapper extends React.Component {
  componentDidMount() {
    this.props.subscribeToMore();
  }
  render() {
    return (<>{this.props.children}</>);
  }
}

    <SubscriptionWrapper subscribeToMore={
      () => {
        if (props.table.qSubscription)
          subscribeToMore({
            document: props.table.qSubscription,
            variables: {
              current: loading ? initData.current : data[props.table.nData].current,
              pageSize: loading ? initData.pageSize : data[props.table.nData].pageSize,
              filters: initData.filters,
              sorterS: initData.sorters
            },
            updateQuery: (prev, {subscriptionData}) => {
              console.log('subscribe triggered');
              let prevData = prev[props.table.nData].data;
              const newRow = subscriptionData.data[props.table.nSub].modified;
              const pos = prevData.findIndex(i => i._id === newRow._id);
              if (pos !== -1) {
                return {
                  [props.table.nData]: {
                    ...prev[props.table.nData],
                    data: prevData.map((d,i) => i === pos ? newRow : d),
                  }
                };
              } else {
                console.log('inserting new row unsupported at this time');
                return prev;
              }
            }
          });
      }
    }>
    </SubscriptionWrapper>
*/

export default DataTable;
