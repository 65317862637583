import React from 'react';
import { useQuery } from '@apollo/client';
import { Space, Button, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function(filterQuery, filterIndex, dataIndex) {
  return {
    filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
      const { data, loading, error } = useQuery(filterQuery, {
        variables: {
          column: dataIndex
        },
        fetchPolicy: 'no-cache'
      });

      if (loading) return <div style={{ padding: 8 }}>loading... </div>;
      if (error) return <div style={{ padding: 8 }}><code>{JSON.stringify(error, null, 2)}</code></div>;
      else {
        const { filters, type } = data[filterIndex];
        const options = filters.map(f => <Select.Option key={f.key}>{f.value}</Select.Option>);
        let value = null;
        try {
          const temp = JSON.parse(selectedKeys);
          value = temp.value;
        } catch (err) {
          // ignore error
        }

        return (
          <div style={{ padding: 8 }}>
            <Select
              showSearch
              value={value}
              onChange={e => setSelectedKeys(JSON.stringify({ type, value: e}))}
              style={{ marginBottom: 8, display: 'block' }}
            >
              {options}
            </Select>
            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
                // TODO: make disabled when table is polling
              >
                Search
              </Button>
              <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                Reset
              </Button>
            </Space>
          </div>
        );
      }
    },
    filterIcon(filtered) { return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />; },
    filterMultiple: false
  };
}