import React from 'react';
import { gql } from '@apollo/client';
import { Tag } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';

const triggers = t => {
  if ( Array.isArray(t) ) {
    return t.map((d,i) => (
      <Tag key={i}>{d.ending_action.name} ➤ {d.start_action.name}</Tag>
    ));
  }
  return <></>;
};
triggers.displayName = 'Triggers';

const data_type = {
  title: 'Data Types',
  icon: <FileAddOutlined />,
  roles: [ 'Admin' ],
  type: 'table',
  pageSize: 25,
  pollInterval: 10000,
  data: {
    query: gql`
      query DataTypePagination($current:Int!,$pageSize:Int!) {
        dataTypePagination(current:$current,pageSize:$pageSize) {
          current
          pageSize
          total
          data {
            _id name source filename_identifier triggers { ending_action {name} start_action {name} }
          }
        }
      }
    `,
    nData: 'dataTypePagination',
    columns: [
      {title: 'Name', dataIndex: 'name'},
      {title: 'Source', dataIndex: 'source'},
      {title: 'Identifier', dataIndex: 'filename_identifier'},
      {title: 'Triggers', dataIndex: 'triggers', render: triggers}
    ]
  }
};

export default data_type;
