import React from 'react';
import { gql } from '@apollo/client';
import { Table } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { UserRoleEnum } from '@mts/user-core';
import hasProp from '../../helpers/hasProp';
import filterable from '../../components/Filterable';
import filterableRegex from '../../components/FilterableRegex';

function renderDuration(_f, row) {
  let started_at = null;
  let finished_at = null;
  if ( 'started_at' in row && row.started_at != null ) started_at = new Date(row.started_at);
  if ( 'finished_at' in row && row.finished_at != null ) finished_at = new Date(row.finished_at);
  if ( 'failed_at' in row && row.failed_at != null ) finished_at = new Date(row.failed_at);
  if ( started_at && !finished_at ) {
    return (((new Date()).getTime() - started_at.getTime()) / 1000).toFixed(3);
  }
  if ( started_at && finished_at ) {
    return ((finished_at.getTime() - started_at.getTime()) / 1000).toFixed(3);
  }
  return null;
}

const expandedStepRender = record => (
  <Table 
    rowKey={r => r._id}
    columns={[
      {title: 'Time', dataIndex: 'timestamp', width: 200},
      {title: 'Output', dataIndex: 'text'}
    ]}
    dataSource={record.output}
    size='small'
    scroll={{ y: 240 }}
    pagination={false}
    bordered
  />
);
expandedStepRender.displayName = 'Step Details';

const expandedJobRender = record => (
  <Table rowKey={r => r._id} 
    columns={[
      {title:'Description',dataIndex:'description'},
      {title:'Elapsed',dataIndex:'elapsed'},
      {title:'Started',dataIndex:'started_at'},
      {title:'Finished',dataIndex:'finished_at'},
      {title:'Duration (s)',render:renderDuration,align:'right'},
      {title:'Error',dataIndex:'error'}
    ]}
    dataSource={record.job_step}
    size='small'
    scroll={{ y: 240 }}
    pagination={false}
    bordered
    rowExpandable={step => 'output' in step && step.output.length > 0}
    expandedRowRender={expandedStepRender}
  />
);
expandedJobRender.displayName = 'Job Details';

const filterQuery = gql`
query JobFilters($column: String) {
  jobFilters(column: $column) {
    type,
    filters { key, value }
  }
}
`;
const filterIndex = 'jobFilters';

const content = {
  title: 'All Jobs',
  icon: <ImportOutlined />,
  roles: UserRoleEnum,
  type: 'table',
  pollInterval: 0,
  data: {
    query:  gql`
      query JobPagination($current:Int!,$pageSize:Int!,$filters:JobFilters,$sorters:Sorters) {
        jobPagination(current:$current,pageSize:$pageSize,filters:$filters,sorters:$sorters) {
          current
          pageSize
          total
          data {
            _id 
            action { name } 
            data_ingest { filename control { asset_id { asset_id } } } 
            data_type { name } 
            priority 
            mtime 
            state
            sf_pool_size
            pid
            can_start_at
            started_at
            finished_at
            canceled_at
            error
            kill
            retries
            job_step {
              _id
              description
              elapsed
              started_at
              finished_at
              output { _id timestamp text }
              error
            }
          }
        }
      }
    `,
    defaultSort: '',
    nData: 'jobPagination',
    columns: [
      {title: 'Asset', dataIndex: 'data_ingest', render: d => {
        return d && d.control && d.control.asset_id && d.control.asset_id.asset_id ? d.control.asset_id.asset_id : '<none>';
      }, ...filterable(filterQuery, filterIndex, 'data_ingest.control.asset_id')},
      {title:'Action',dataIndex:'action', render: d => d ? d.name : '', ...filterable(filterQuery, filterIndex, 'action')},
      {title:'File',dataIndex:'data_ingest', render: d => d ? d.filename : '', ...filterableRegex()},
      {title:'Data Type',dataIndex:'data_type', render: d => d ? d.name : ''},
      {title:'Priority',dataIndex:'priority',align:'right'},
      {title: 'Finished At', dataIndex: 'finished_at'},
      {title:'Modification Time',dataIndex:'mtime', sorter: true},
      {title:'State',dataIndex:'state',render: s => s.replace(/_/g, ' '), ...filterable(filterQuery, filterIndex, 'state')},
      {title:'Duration (s)',dataIndex:'finished_at',render:renderDuration,align:'right'},
      {title:'SF Pool Size',dataIndex:'sf_pool_size',align:'right'},
      {title:'PID',dataIndex:'pid',align:'right'}
    ],
    rowExpandable: (record) => hasProp(record,'job_step') && record.job_step.length > 0,
    expandedRowRender: expandedJobRender
  }
};

export default content;
