import React from 'react';
import { Row, Button, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

export default function() {
  return {
    // eslint-disable-next-line no-unused-vars
    filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
      let value = [];
      try {
        //console.log('selectedKeys', selectedKeys);
        const temp = JSON.parse(selectedKeys);
        value = temp.value;
      } catch (err) {
        // ignore error
      }
      //console.log('value', value);
      return (
        <div style={{ padding: 8 }}>
          <Row>
            Regex:
            <Input
              value={value}
              onChange={d => setSelectedKeys(JSON.stringify({ type: 'search', value: d.target.value }))}
            />
          </Row>
          <Row>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
                Search
            </Button>
            <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
                Reset
            </Button>
          </Row>
        </div>
      );
    },
    filterIcon(filtered) { return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />; },
    filterMultiple: false,
  };
}