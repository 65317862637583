import React from 'react';
import { gql } from '@apollo/client';
import { Table } from 'antd';
import { HistoryOutlined, CheckOutlined } from '@ant-design/icons';
import hasProp from '../../helpers/hasProp';

const successful = d => {
  return <CheckOutlined style={{color: d ? 'green' : 'red'}} />;
};
successful.displayName = 'Successful';

const expandedRowRender = d => (
  <Table 
    columns={[
      {title: 'Time', dataIndex: 'timestamp'},
      {title: 'Message', dataIndex:'text'}
    ]} 
    dataSource={d.messages} 
    pagination={false} 
    rowKey={d => d._id} 
  />
);
expandedRowRender.displayName = 'Details';

const db_history = {
  title: 'Database History',
  icon: <HistoryOutlined />,
  roles: [ 'Admin' ],
  type: 'table',
  data: {
    query: gql`
      query DbHistoryPagination($current:Int!,$pageSize:Int!) {
        dbHistoryPagination(current:$current,pageSize:$pageSize) {
          current
          pageSize
          total
          data {
            _id command started_at finished_at success messages { _id timestamp text }
          }
        }
      }
    `,
    nData: 'dbHistoryPagination',
    columns: [
      {title: 'Command', dataIndex: 'command'},
      {title: 'Start Time', dataIndex: 'started_at'},
      {title: 'End Time', dataIndex: 'finished_at'},
      {title: 'Successful', dataIndex: 'success', render: successful, align: 'center'}
    ],
    rowExpandable: d => hasProp(d,'messages') && d.messages.length > 0,
    expandedRowRender
  }
};

export default db_history;
