import React, { useEffect } from 'react';
import { Table } from 'antd';
import { useQuery } from '@apollo/client';
import hasProp from '../helpers/hasProp';

function ActiveTable(props) {

  //  const { data, loading, error, stopPolling, startPolling } = useQuery(props.table.query);
  const { data, loading, error, stopPolling, startPolling } = useQuery(props.table.query, { });

  useEffect(() => {
    startPolling(props.table.pollInterval || 500);
    return () => { stopPolling(); };
  },[startPolling,stopPolling]);

  if ( error ) return (<div><code>{JSON.stringify(error,null,2)}</code></div>);

  return (
    <Table
      pagination={false}
      columns={props.table.columns}
      rowKey={d => d._id}
      dataSource={loading ? [] : data[props.table.nData].data}
      loading={loading}
      expandable={{
        rowExpandable: hasProp(props.table,'rowExpandable') ? props.table.rowExpandable : () => false,
        expandedRowRender: hasProp(props.table,'expandedRowRender') ? props.table.expandedRowRender : () => (<></>)
      }}
    />
  );
}

export default ActiveTable;
