import React from 'react';
import { gql } from '@apollo/client';
import { Table, Progress } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { UserRoleEnum } from '@mts/user-core';
import hasProp from '../../helpers/hasProp';

const renderProgress = d => (
  <Progress percent={d * 100} />
);
renderProgress.displayName = 'Progress';

const renderDuration = (_f, row) => {
  let started_at = null;
  let finished_at = null;
  if ( 'started_at' in row && row.started_at != null ) started_at = new Date(row.started_at);
  if ( 'finished_at' in row && row.finished_at != null ) finished_at = new Date(row.finished_at);
  if ( 'failed_at' in row && row.failed_at != null ) finished_at = new Date(row.failed_at);
  if ( started_at && !finished_at ) {
    return (((new Date()).getTime() - started_at.getTime()) / 1000).toFixed(3);
  }
  if ( started_at && finished_at ) {
    return ((finished_at.getTime() - started_at.getTime()) / 1000).toFixed(3);
  }
  return null;
};
renderDuration.displayName = 'Duration (s)';

const expandedStepRender = record => (
  <Table 
    rowKey={r => r._id}
    columns={[
      {title: 'Time', dataIndex: 'timestamp', width: 200},
      {title: 'Output', dataIndex: 'text'}
    ]}
    dataSource={record.output}
    size='small'
    scroll={{ y: 240 }}
    pagination={false}
    bordered
  />
);
expandedStepRender.displayName = 'Step Details';

const expandedJobRender = record => (
  <Table
    rowKey={r => r._id}
    columns={[
      {title:'Description',dataIndex:'description'},
      {title:'Elapsed',dataIndex:'elapsed'},
      {title:'Started',dataIndex:'started_at'},
      {title:'Finished',dataIndex:'finished_at'},
      {title:'Duration (s)',render:renderDuration,align:'right'},
      {title:'Error',dataIndex:'error'}
    ]}
    dataSource={record.job_step}
    size='small'
    scroll={{ y: 240 }}
    pagination={false}
    bordered
    rowExpandable={step => 'output' in step && step.output.length > 0}
    expandedRowRender={expandedStepRender}
  />
);
expandedJobRender.displayName = 'Job Detail';

// Matches the sort order in server/db/models/job_runner.js _do_assign():
// { priority: 1, mtime: -1, can_start_at: 1 }
// but with "running" jobs first no matter what
function defaultSorter(A, B) {
  let result = A._order - B._order;
  if ( result != 0 ) return result;
  result = B.priority - A.priority;
  if ( result != 0 ) return result;
  result = A.mtime - B.mtime;
  if ( result != 0 ) return result;
  return B.can_start_at - A.can_start_at;
}


const content = {
  title: 'Active Jobs',
  icon: <ImportOutlined />,
  roles: UserRoleEnum,
  type: 'active_table',
  data: {
    pollInterval: 500,
    query:  gql`
      query JobActive {
        jobActive {
          data {
            _id action {name} data_ingest { filename } data_type { name } priority mtime state sf_pool_size pid progress _order
            can_start_at started_at finished_at canceled_at error kill retries
            job_step {
              _id description elapsed started_at finished_at error
            }
          }
        }
      }
    `,
    nData: 'jobActive',
    columns: [
      {title:'Action',dataIndex:'action', render: d => d ? d.name : '', sorter: defaultSorter, defaultSortOrder: 'ascending'},
      {title:'State',dataIndex:'state', render: s => s.replace(/_/g, ' ')},
      {title:'Active SF Size',dataIndex: 'sf_pool_size',align: 'right', render: (d,r) => r.state == 'Running' ? d : ''},
      {title:'File',dataIndex:'data_ingest', render: d => d ? d.filename : ''},
      {title:'Can Start',dataIndex:'can_start_at'},
      {title:'Started',dataIndex:'started_at'},
      {title:'Duration (s)',dataIndex:'finished_at',render:renderDuration,align:'right'},
      //{title:'Progress',dataIndex:'progress', width: 200, render: renderProgress},
      {title:'Retries',dataIndex:'retries',align:'right'}
    ],
    rowExpandable: (record) => hasProp(record,'job_step') && record.job_step.length > 0,
    expandedRowRender: expandedJobRender
  }
};

export default content;
