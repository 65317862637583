import React from 'react';
import { Typography, Spin } from 'antd';
import { useQuery } from '@apollo/client';

const { Text } = Typography;

/* this is for subscriptions
let unsub = null;

function Log(props) {
  const [data, setData] = useState([]);
  const [getData, { subscribeToMore, error }] = useLazyQuery(props.data.qInit, {
    onCompleted: data => {
      console.log('first completed', data);
      setData(data[props.data.nInit]);
      if (subscribeToMore) {
        unsub = subscribeToMore({
          document: props.data.qSub,
          variables: {
            limit: props.data.length
          },
          updateQuery: (prev, { subscriptionData }) => {
            console.log('recieved subscription', subscriptionData);
            if (subscriptionData && subscriptionData.data && subscriptionData.data[props.data.nSub]) {
              setData(...data, subscriptionData.data[props.data.nSub]);
              return data;
            } else return prev;
          }
        });
      }
    }
  });
  useEffect(() => {
    getData({
      variables: {
        limit: props.data.length
      }
    });
    return () => {
      if (typeof unsub === 'function') unsub();
    };
  }, []);

  if (data.length === 0) return <Spin />;
  if (error) return <Text>Error: <code>{JSON.stringify(error, null, 2)}</code></Text>;
  return (
    <pre>
      {data
        .map(d => `[${d.timestamp}]:${d.message.split('\n').join('\n                           ')}`)
        .filter((_d,i) => i <= props.data.length)
        .reverse()
        .join('\n')
      }
    </pre>
  );
}
*/

function logLevelText(level) {
  switch(level) {
    case 'error': return <span className='logLevel_error'>ERROR ⚠</span>;
    case 'warn': return <span className='logLevel_warn'>WARN ⚠</span>;
    default: return <span className={'logLevel_' + level}>{level.toUpperCase()}</span>;
  }
}

function messageText(level, message) {
  switch(level) {
    case 'error':
    case 'warn': 
    case 'banner': return <pre className='logLevel_highlight' dangerouslySetInnerHTML={{ __html: message }}></pre>;
    default: return <pre dangerouslySetInnerHTML={{ __html: message }}></pre>;
  }
}

function Log(props) {
  const { loading, error, data } = useQuery(props.data.qInit, {
    variables: {
      limit: props.data.length
    },
    pollInterval: 1000
  });
  if (loading) return <Spin />;
  if (error) return <Text>Error: <code>{JSON.stringify(error, null, 2)}</code></Text>;
  return (
    <table className="logTable">
      <tbody>
        {data[props.data.nInit]
          .map(d => (
            <tr key={d._id}>
              <td width="200px">{d.timestamp}</td>
              <td width="250px">{d.service}</td>
              <td width="250px">{d.source.replace(/^.*KMAP-MTS-Data-Ingester\/server\//, '')}</td>
              <td width="80px">{logLevelText(d.level)}</td>
              <td>{messageText(d.level, d.message)}</td>
            </tr>
          ))
          .filter((_d,i) => i <= props.data.length)
          //.join('\n')
        }
      </tbody>
    </table>
  );
}
export default Log;