import React from 'react';
import { gql } from '@apollo/client';
import { Checkbox } from 'antd';
import { ToolOutlined } from '@ant-design/icons';

const runnable = d => (
  <Checkbox checked={d} disabled={true} />
);
runnable.displayName = 'Runnable';

const hasOptions = d => (
  <Checkbox checked={d} disabled={true} />
);
hasOptions.displayName = 'Has Options';

const action = {
  title: 'Actions',
  icon: <ToolOutlined />,
  roles: [ 'Admin' ],
  type: 'table',
  data: {
    query: gql`
      query ActionPagination($current:Int!,$pageSize:Int!) {
        actionPagination(current:$current,pageSize:$pageSize) {
          current
          pageSize
          total
          data { _id name runnable has_options }
        }
      }
    `,
    nData: 'actionPagination',
    columns: [
      {title: 'Action', dataIndex: 'name'},
      {title: 'Runnable', dataIndex: 'runnable', render: runnable,align:'center'},
      {title: 'Has Options', dataIndex: 'has_options', render: hasOptions,align:'center'}
    ]
  }
};

export default action;
