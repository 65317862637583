import {useEffect, useState, useContext, useRef, useLayoutEffect} from 'react';
import { Spin } from 'antd';
import axios from 'axios';
import * as d3 from 'd3';
import { ContentContext } from '../App';

const dx = 30;
const dy = 200;
const margin = {top: 10, right: 120, bottom: dx, left: 120};

const diagonal = d3.linkHorizontal().x(d => d.y).y(d => d.x);

const build_chart = function(data, width, height) {

  const tree = d3.tree().nodeSize([dx, dy]);
  const root = d3.hierarchy(data);

  root.x0 = dy / 2;
  root.y0 = 0;
  
  root.descendants().forEach((d, i) => {
    d.id = i;
    d._children = d.children;
  });
  
  const svg = d3.create('svg')
    .attr('viewBox', [-margin.left, -margin.top, width, height])
    .style('font', '10px sans-serif')
    .style('user-select', 'none');

  const gLink = svg.append('g')
    .attr('fill', 'none')
    .attr('stroke', '#555')
    .attr('stroke-opacity', 0.4)
    .attr('stroke-width', 1.5);

  const gNode = svg.append('g')
    .attr('cursor', 'pointer')
    .attr('pointer-events', 'all');

  function update(source) {
    const duration = 250;
    const nodes = root.descendants().reverse();
    const links = root.links();

    // Compute the new tree layout.
    tree(root);

    let left = root;
    let right = root;
    root.eachBefore(node => {
      if (node.x < left.x) left = node;
      if (node.x > right.x) right = node;
    });

    const height = right.x - left.x + margin.top + margin.bottom + 12;

    const transition = svg.transition()
      .duration(duration)
      .attr('viewBox', [-margin.left, left.x - margin.top, width, height])
      .tween('resize', window.ResizeObserver ? null : () => () => svg.dispatch('toggle'));

    // Update the nodes…
    const node = gNode.selectAll('g')
      .data(nodes, d => d.id);

    // Enter any new nodes at the parent's previous position.
    const nodeEnter = node.enter().append('g')
      .attr('transform', () => `translate(${source.y0},${source.x0})`)
      .attr('fill-opacity', 0)
      .attr('stroke-opacity', 0)
      .on('click', (_event, d) => {
        d.children = d.children ? null : d._children;
        update(d);
      });

    nodeEnter.append('circle')
      .attr('r', 2.5)
      .attr('fill', d => d._children ? '#555' : '#999')
      .attr('stroke-width', 10);

    nodeEnter.append('text')
      .attr('dy', '0.31em')
      .attr('x', d => d._children ? -6 : 6)
      .attr('text-anchor', d => d._children ? 'end' : 'start')
      .text(d => d.data.name)
      .append('tspan')
      .attr('dy', '1em')
      .attr('x', d => d._children ? -6 : 6)
      .text(d => d.data.data_type)
      .append('tspan')
      .attr('dy', '1em')
      .attr('x', d => d._children ? -6 : 6)
      .text(d => d.data.strategy);
    /*
      .clone(true).lower()
      .attr('stroke-linejoin', 'round')
      .attr('stroke-width', 3)
      .attr('stroke', 'black');
      */

    // Transition nodes to their new position.
    //const _nodeUpdate = 
    node.merge(nodeEnter).transition(transition)
      .attr('transform', d => `translate(${d.y},${d.x})`)
      .attr('fill-opacity', 1)
      .attr('stroke-opacity', 1);

    // Transition exiting nodes to the parent's new position.
    //const _nodeExit = 
    node.exit().transition(transition).remove()
      .attr('transform', () => `translate(${source.y},${source.x})`)
      .attr('fill-opacity', 0)
      .attr('stroke-opacity', 0);

    // Update the links…
    const link = gLink.selectAll('path')
      .data(links, d => d.target.id);

    // Enter any new links at the parent's previous position.
    const linkEnter = link.enter().append('path')
      .attr('d', () => {
        const o = {x: source.x0, y: source.y0};
        return diagonal({source: o, target: o});
      });

    // Transition links to their new position.
    link.merge(linkEnter).transition(transition)
      .attr('d', diagonal);

    // Transition exiting nodes to the parent's new position.
    link.exit().transition(transition).remove()
      .attr('d', () => {
        const o = {x: source.x, y: source.y};
        return diagonal({source: o, target: o});
      });

    // Stash the old positions for transition.
    root.eachBefore(d => {
      d.x0 = d.x;
      d.y0 = d.y;
    });
  }

  update(root);

  console.log(root);

  return svg.node();
};

function Chart(props) {
  const content_context = useContext(ContentContext);
  const ref = useRef(null);
  useLayoutEffect(() => {
    ref.current.replaceChildren();
    ref.current.appendChild(build_chart(props.data, content_context.contentOffsets.width, content_context.contentOffsets.height));
    return () => ref.current.replaceChildren();
  }, [props.data]);
  return <div ref={ref} style={{ width: '100%', height: '100%' }}/>;
}


export function ActionLinks() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});

  useEffect(() => {
    try {
      axios.get('/reports/action_links').then(res => {
        console.log('received', res.data);
        setData(res.data);
      });
    } catch(err) {
      setError(JSON.stringify(err, null, 2));
    } finally {
      setLoading(false);
    }
  },[]);

  return loading ? <Spin/> : typeof error === 'string' ? <code>{error}</code> : <Chart data={data} style={{ width: '100vh', height: '100vh' }}/>;
}