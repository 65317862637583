import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Spin, Table, Button, message } from 'antd';

function Files(props) {
  const triggerUpload = (file) => {
    axios.post('/file_upload/upload/' + file)
      .then(() => {
        message.info(file + ' upload triggered');
      })
      .catch(err => {
        message.error(file + ' upload failed');
        console.error(err);
      });
  };
  const triggerAll = () => {
    axios.post('/file_upload/upload_all')
      .then(() => {
        message.info('upload all triggered');
      })
      .catch(err => {
        message.error('upload all failed');
        console.error(err);
      });
  };


  const columns = [
    { title: 'File', dataIndex: 'file', key: 'file' },
    {
      title: 'Upload',
      dataIndex: 'upload',
      key: 'upload',
      render: (_, rec) => <Button onClick={() => triggerUpload(rec.file)}>Trigger Upload</Button>
    }
  ];

  const dataSource = props.files.map((f,i) => ({
    key: i,
    file: f
  }));

  return (
    <>
      <Button onClick={triggerAll}>Trigger All</Button>
      <Table dataSource={dataSource} columns={columns}></Table>
    </>
  );
}


export function FileUpload() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [files, setFile] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get('/file_upload/files')
      .then(res => {
        setFile(res.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });

  }, []);


  return loading ? <Spin /> : error ? <div>{JSON.stringify(error)}</div> : <Files files={files} />;
}