import React, { useState, useRef, useEffect, useContext } from 'react';
import { Form, Input, message } from 'antd';
import { useMutation } from '@apollo/client';

function Mutate(query) {
  const [mutate] = useMutation(query);
  return mutate;
}

const EditableContext = React.createContext(null);

function EditableRow(props) {
  delete props.index;
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}

function EditableCell({title, editable, children, dataIndex, record, editableRows, rowChanged, ...restProps}) {
  
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) inputRef.current.focus();
  },[editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({[dataIndex]:record[dataIndex]});
  };

  const saveRow = editableRows ? Mutate(editableRows.query) : async () => null;

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      const variables = editableRows.queryVariables({...record, ...values});
      if (!rowChanged(variables))
        message.warning('Row not changed');
      else {
        if (editableRows.validate) {
          try {
            editableRows.validate({...record, ...values});
          } catch(err) {
            message.error(err.message);
            console.log(err);
            return;
          }
        }
        console.log('running mutation with ',variables);
        const result = await saveRow({ variables });
        const error = result[Object.keys(result)[0]].error;
        if (error) throw new Error(error);
      }
    } catch(err) {
      console.log('Save failed: '+err);
    }
  };

  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item style={{margin:0}} name={dataIndex} rules={[
        {required: true, message: `${title} is required`}
      ]}>
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
}

export {
  EditableCell,
  EditableRow
};
