import log from './log';
import control from './control';
import data_ingest from './data_ingest';
import job from './job';
import infrastructure from './infrastructure';
import { analytics } from './analytics';
import { UserPlugin } from '@mts/user-react';
import { SystemHealthPlugin } from '@mts/system_health-react';

export const content = {
  user: {
    title: UserPlugin.menu.label,
    key: UserPlugin.menu.key,
    icon: UserPlugin.menu.icon,
    type: 'user_content',
    roles: ['Admin']
  },
  log,
  control,
  data_ingest,
  job,
  infrastructure,
  analytics,
  system_health: {
    title: SystemHealthPlugin.menu.label,
    key: SystemHealthPlugin.menu.key,
    icon: SystemHealthPlugin.menu.icon,
    type: 'system_health',
    roles: ['Admin', 'Public']
  }
};
