import React from 'react';
import { gql } from '@apollo/client';
import { InteractionOutlined } from '@ant-design/icons';
import { UserRoleEnum } from '@mts/user-core';

const job_runner = {
  title: 'Job Processes',
  icon: <InteractionOutlined />,
  roles: UserRoleEnum,
  type: 'table',
  data: {
    pollInterval: 500,
    query:  gql`
      query JobRunnerPagination($current:Int!,$pageSize:Int!) {
        jobRunnerPagination(current:$current,pageSize:$pageSize) {
          current
          pageSize
          total
          data {
            _id job { _id } action { name } data_type { name } pid sf_size
          }
        }
      }
    `,
    nData: 'jobRunnerPagination',
    columns: [
      {title:'Job ID',dataIndex:'job', render: d => d._id},
      {title:'Action',dataIndex:'action', render: d => d.name},
      {title:'Data Type',dataIndex:'data_type', render: d => d == null ? '' : d.name},
      {title:'Process ID',dataIndex:'pid',align:'right'},
      {title:'Snowflake Size',dataIndex:'sf_size',align:'right'}
    ]
  }
};

export default job_runner;
