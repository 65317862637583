//import { ApolloLink, Observable } from '@apollo/client/core';
//import { print } from 'graphql';
//import { createClient } from 'graphql-ws';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  //split
} from '@apollo/client';
//import { getMainDefinition } from '@apollo/client/utilities';

/*
class WebSocketLink extends ApolloLink {
  constructor(options) {
    super();
    this.client = createClient(options);
  }
  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (Array.isArray(err))
              // GraphQLError[]
              return sink.error(
                new Error(err.map(({ message }) => message).join(', ')),
              );

            if (err instanceof CloseEvent)
              return sink.error(
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`, // reason will be available on clean closes only
                ),
              );

            return sink.error(err);
          },
        },
      );
    });
  }
}
*/

const base = protocol => {
  if ( typeof window === 'undefined' ) {
    console.log('"window" is not a thing!');
    return `${protocol || 'http'}://localhost:3001`;
  }
  let result = window.location.origin.toString();
  if ( protocol ) result = result.replace(/^[^:]+:/, `${protocol}:` );
  return result;
};

const httpLink = new HttpLink({
  uri: `${base()}/graphql`,
  credentials: 'include'
});

/*
const wsLink = new WebSocketLink({
  url: `${base('ws')}/subscriptions`,
  
  connectionParams: () => {
    const session = getSession();
    if (!session) {
      return {};
    }
    return {
      Authorization: `Bearer ${session.token}`,
    };
  },
  
});

const splitLink = split(
  ({query}) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);
*/
const client = new ApolloClient({
  link: httpLink, // splitLink,
  cache: new InMemoryCache(
  // the following is for caching results. It needs some major work because it's not
  // designed for filters/sorting coming form the backend. It's doable though
  /*{
    typePolicies: {
      Query: {
        fields: Object.keys(content)
          .filter(k => ['table'].includes(content[k].type))
          .reduce((acc,cur) => {
            acc[cur] = {
              keyArgs: ["current","pageSize","filters"],
              merge(existing,incoming) {
                return incoming;
              }
            }
            return acc;
          },{})
      }
    }
  }*/)
});

export default client;
