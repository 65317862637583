import React, { useContext, createContext, useState, useEffect, useRef } from 'react';
import { Layout, Row, Col } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserContext, UserBar, UserPlugin } from '@mts/user-react';
import { SystemHealthPlugin } from '@mts/system_health-react';
import { PlaybackInspectorPlugin } from '@mts/reports-playback_inspector-react';
import Breadcrumbs from './components/Breadcrumbs';
import { content } from './content';
import ContentSider from './components/ContentSider';
import DataTable from './components/DataTable';
import ActiveTable from './components/ActiveTable';
import Log from './components/Log';
import { ActionLinks } from './components/ActionLinks';
import { ControlRecords } from './components/ControlRecords';
import { PlaybackControlRecords } from './components/PlaybackControlRecords';
import { FileUpload } from './components/FileUpload';
import './index.less';

const { Content } = Layout;

const plugins = [UserPlugin, SystemHealthPlugin, PlaybackInspectorPlugin];

const defaultPageSize = 10;
const defaultPollInterval = 1000;

function ActiveContent({ activeContent, extra_path }) {
  switch (activeContent.type) {
    case 'table': return (
      <DataTable pageSize={activeContent.pageSize || defaultPageSize} table={activeContent.data} pollInterval={typeof activeContent.pollInterval === 'number' ? activeContent.pollInterval : defaultPollInterval } /> 
    );
    case 'active_table': return (
      <ActiveTable table={activeContent.data} />
    );
    case 'log': return (
      <Log data={activeContent.data} />
    );
    case 'user_content': return UserPlugin.content;
    case 'system_health': return SystemHealthPlugin.content;
    case 'playback_inspector': return PlaybackInspectorPlugin.content;
    case 'action_links': return <ActionLinks />;
    case 'control_records': return <ControlRecords extra_path={extra_path} />;
    case 'playback_control_records': return <PlaybackControlRecords extra_path={extra_path} />;
    case 'file_upload': return <FileUpload />;
    case 'custom': return activeContent.custom;
    default: return (
      <p>
        Unsupported content type: {activeContent.type}
      </p>
    );
  }
}

function Modal({ plugins, activeContent }) {
  const pos = plugins.findIndex(p => p.menu.key === activeContent.key);
  if (pos === -1) {
    return <></>;
  } else {
    const modal = plugins[pos].modal;
    if (typeof modal === 'undefined') return <></>;
    else return modal;
  }
}

function Providers({ plugins, children }) {
  let Last = <>{children}</>;
  for (let i = 0; i < plugins.length; i++) {
    const P = plugins[i].provider;
    if (typeof P === 'undefined') continue;
    Last = <P>{Last}</P>;
  }
  return Last;
}

export function App() {
  const contentRef = useRef(null);
  const [contentOffsets, setContentOffsets] = useState(initContentOffsets);
  const user_context = useContext(UserContext);
  const { pathname } = useLocation();
  const nav = useNavigate();

  useEffect(() => {
    function update() {
      if (contentRef.current !== null) setContentOffsets(contentRef.current.getBoundingClientRect());
    }
    window.addEventListener('resize', update);
    update();
    return () => window.removeEventListener('resize', update);
  }, []);

  const activeArr = pathname.split('/').filter(p => p !== '');

  const setActive = a => {
    if (a !== activeArr.join('/')) nav(`/${a}`);
  };

  const getActiveContent = (obj,pos = 0) => {
    const c = obj[activeArr[pos]];
    if (pos === activeArr.length - 1) return [c, []]; // return if last in path
    if (typeof c.children === 'undefined') return [c, activeArr.slice(pos + 1)]; // return if no children
    return getActiveContent(c.children,pos + 1);
  };

  const getVisibleContent = (obj) => {
    const result = {};
    for ( let [item, value] of Object.entries(obj) ) {
      if ( value.requireRoleAdmin && !user_context.value.user.roles.includes('Admin') ) continue;
      if ( value.type == 'group' ) {
        result[item] = { ...value, children: getVisibleContent(value.children) };
      } else {
        result[item] = value;
      }
    }
    return result;
  };

  const [activeContent, extra_path] = getActiveContent(getVisibleContent(content));


  return (
    <Layout theme="dark" style={{height:'100%'}}>
      <Providers plugins={plugins} >
        <ContentSider
          active={activeArr.join('/')}
          setActive={setActive}
          content={content}
        />
        <Modal plugins={plugins} activeContent={activeContent} />
        <Layout style={{verticalAlign: 'top', height: '100%' }}>
          <Layout className='ant-header'>
            <Row align='middle'>
              <Col span={20}><Breadcrumbs activeArr={activeArr} content={content} /></Col>
              <Col span={4} style={{ textAlign: 'right' }}><UserBar /></Col>
            </Row>
          </Layout>
          <Content ref={contentRef} className='site-layout-background' style={{padding: 24, margin: 0, minHeight: 280, verticalAlign: 'top' }}>
            <ContentContext.Provider value={{ contentOffsets }}>
              <ActiveContent activeContent={activeContent} extra_path={extra_path} />
            </ContentContext.Provider>
          </Content>
        </Layout>
      </Providers>
    </Layout>
  );
}

const initContentOffsets = {
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  width: 0,
  height: 0,
  x: 0,
  y: 0,
  toJSON() {
    return JSON.stringify(initContentOffsets);
  },
};

export const ContentContext = createContext({
  contentOffsets: initContentOffsets,
});
