import React from 'react';
import { gql } from '@apollo/client';
import hasProp from '../../helpers/hasProp';
import { Checkbox } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { UserRoleEnum } from '@mts/user-core';

const expandedRowRender = record => (
  <div dangerouslySetInnerHTML={{__html: record.tooltip }} />
);
expandedRowRender.displayName = 'Tooltip';

const build_editable = (user) => {
  const editable = (d) => {
    return (
      <Checkbox disabled={!user.roles.includes('Admin')} checked={d} />
    );
  };
  editable.displayName = 'Editable';
  return editable;
};


const enableEditable = (user) => {
  if ( user.roles.includes('Admin') ) {
    return (rec) => rec.is_editable;
  } else {
    return false;
  }
};

const content = {
  title: 'Settings',
  icon: <SettingOutlined />,
  roles: UserRoleEnum,
  type: 'table',
  data: {
    bordered: true,
    query:  gql`
      query RegistryPagination($current:Int!,$pageSize:Int!,$filters:RegistryFilters!,$sorters:Sorters) {
        registryPagination(current:$current,pageSize:$pageSize,filters:$filters,sorters:$sorters) {
          current
          pageSize
          total
          data { _id item value is_editable tooltip }
        }
      }
    `,
    qSubscription: gql`
      subscription RegistrySubscribePagination($current:Int!,$pageSize:Int!,$filters:RegistryFilters!,$sorters:Sorters) {
        registrySubscribePagination(current:$current,pageSize:$pageSize,filters:$filters,sorters:$sorters) {
          prevId
          modified { _id item value is_editable tooltip }
        }
      }
    `,
    nData: 'registryPagination',
    nSub: 'registrySubscribePagination',
    columns: (user) => {
      const editable = build_editable(user);
      return [
        {title:'Setting',dataIndex:'item', sorter: true},
        {title:'Value',dataIndex:'value', sorter: true, editable: enableEditable(user)},
        {title: 'Editable',dataIndex:'is_editable', render: editable, align: 'center'}
      ];
    },
    rowExpandable: (record) => hasProp(record,'tooltip') && record.tooltip.length > 0,
    expandedRowRender,
    editableRows: {
      query: gql`
        mutation RegistryUpdateValueById($_id: ObjectId!, $value: String!) {
          registryUpdateValueById (_id:$_id, value:$value) {
            error
          }
        }
      `,
      queryVariables: (record) => {
        return {
          _id: record._id,
          value: record.value
        };
      },
      validate: (record) => {
        if (!(new RegExp(record.filter).test(record.value)))
          throw new Error('Value conficts with filter: '+record.filter);
      }
    }
  }
};

export default content;
