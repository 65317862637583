import React from 'react';
import { gql } from '@apollo/client';
import { DeliveredProcedureOutlined } from '@ant-design/icons';
import { UserRoleEnum } from '@mts/user-core';
import filterable from '../components/Filterable';
import filterableRegex from '../components/FilterableRegex';
import filterableDateRange from '../components/FilterableDateRange';

const filterQuery = gql`
query DataIngestFilters($column: String) {
  dataIngestFilters(column: $column) {
    type,
    filters { key, value }
  }
}
`;
const filterIndex = 'dataIngestFilters';

const data_ingest = {
  title: 'Data Ingestions',
  icon: <DeliveredProcedureOutlined />,
  roles: UserRoleEnum,
  type: 'table',
  data: {
    query:  gql`
      query DataIngestPagination($current:Int!,$pageSize:Int!,$filters:DataIngestFilters,$sorters:Sorters) {
        dataIngestPagination(current:$current,pageSize:$pageSize,filters:$filters,sorters:$sorters) {
          current
          pageSize
          total
          data {
            _id
            filename
            control { 
              data_type { name }
            }
            arrive_time
            receive_time
            ingest_start
            ingest_end
            state
            tables_inserted
            tables_skipped
            tables_errored
          }
        }
      }
    `,
    nData: 'dataIngestPagination',
    columns: [
      {title:'File',dataIndex:'filename', ellipsis: true, ...filterableRegex()},
      {title:'Type',dataIndex:'control', render: rec => rec && rec.data_type ? rec.data_type.name : ''},
      {title:'Arrived',dataIndex:'arrive_time', ...filterableDateRange()},
      {title:'Received',dataIndex:'receive_time', ...filterableDateRange()},
      {title:'Ingest Started',dataIndex:'ingest_start', ...filterableDateRange()},
      {title:'Ingest Finished',dataIndex:'ingest_end', ...filterableDateRange()},
      {title:'Status',dataIndex:'state', ...filterable(filterQuery, filterIndex, 'state')},
      {title:'Tables Inserted',dataIndex:'tables_inserted',align:'right'},
      {title:'Tables Skipped',dataIndex:'tables_skipped',align:'right'},
      {title:'Tables Errored',dataIndex:'tables_errored',align:'right'}
    ]
  }
};

export default data_ingest;
