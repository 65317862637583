import React from 'react';
import { gql } from '@apollo/client';
import { Switch } from 'antd';
import { ExpandOutlined } from '@ant-design/icons';
import { UserRoleEnum } from '@mts/user-core';

let userString = localStorage.getItem('user');
let user = false;
if (typeof userString === 'string') user = JSON.parse(userString);

const isAdmin = () => user.role === 'Admin';

const activeSwitch = d => (
  <Switch disabled={!isAdmin()} checked={d} />
);

const content = {
  title: 'Ingestion Limits',
  icon: <ExpandOutlined />,
  roles: UserRoleEnum,
  type: 'table',
  pageSize: 25,
  pollInterval: 5000,
  data: {
    bordered: true,
    query: gql`
      query IngestionLimitPagination($current:Int!,$pageSize:Int!,$sorters:Sorters) {
        ingestionLimitPagination(current:$current,pageSize:$pageSize,sorters:$sorters) {
          current
          pageSize
          total
          data { _id action { name } data_type { name } concurrency_limit expected_duration rolling_average_events max_rolling_average_events sf_min sf_max last_activity active } 
        }
      }
    `,
    nData: 'ingestionLimitPagination',
    columns: [
      { title: 'Action', dataIndex: 'action', sorter: true, render: (d) => d.name },
      { title: 'Data Type', dataIndex: 'data_type', sorter: true, render: (d) => (d == null ? '' : d.name) },
      { title: 'Active', dataIndex: 'active', sorter: false, render: activeSwitch },
      { title: 'Snowflake Min', dataIndex: 'sf_min', sorter: true, editable: isAdmin, align: 'right'},
      { title: 'Snowflake Max', dataIndex: 'sf_max', sorter: true, editable: isAdmin, align: 'right'},
      { title: 'Concurrency Limit', dataIndex: 'concurrency_limit', editable: isAdmin, align: 'right'},
      { title: 'Expected Duration (s)', dataIndex: 'expected_duration', render: (d) => (d / 1000).toFixed(3), align: 'right' },
      { title: 'Active Rolling Average Events', dataIndex: 'rolling_average_events', align: 'right' },
      { title: 'Max Rolling Average Events', dataIndex: 'max_rolling_average_events', editable: isAdmin, align: 'right' },
      { title: 'Last Activity', dataIndex: 'last_activity', render: d => d ? d : ''}
    ],
    editableRows: {
      query: gql`
        mutation IngestionLimitUpdateById($_id: ObjectId!, $concurrency_limit: Int, $max_rolling_average_events: Int, $sf_min: Int, $sf_max: Int, $active: Boolean) {
          ingestionLimitUpdateById (_id:$_id, concurrency_limit: $concurrency_limit, max_rolling_average_events: $max_rolling_average_events, sf_min: $sf_min, sf_max: $sf_max, active: $active) {
            error
          }
        }
      `,
      queryVariables: (record) => ({
        _id: record._id,
        concurrency_limit: Number.parseInt(record.concurrency_limit),
        max_rolling_average_events: Number.parseInt(record.max_rolling_average_events),
        sf_min: Number.parseInt(record.sf_min),
        sf_max: Number.parseInt(record.sf_max)
      }),
      validate: (record) => {
        if (
          Number.isNaN(record.concurrency_limit) ||
          record.concurrency_limit < 0 ||
          Number.parseInt(record.concurrency_limit) != record.concurrency_limit
        )
          throw new Error('Concurrency Limit must be 0 or a positive integer');
        if (
          Number.isNaN(record.max_rolling_average_events) ||
          record.max_rolling_average_events <= 0 ||
          Number.parseInt(record.max_rolling_average_events) !=
            record.max_rolling_average_events
        )
          throw new Error(
            'Max Rolling Average Events must be a positive integer'
          );
        if ( record.sf_min > record.sf_max ) throw new Error('Snowflake Min must be less than or equal to Snowflake Max');
      },
    },
  },
};

export default content;
