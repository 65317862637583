import React from 'react';
import { gql } from '@apollo/client';
import { ReadOutlined } from '@ant-design/icons';

const log = {
  title: 'Log',
  icon: <ReadOutlined />,
  roles: [ 'Admin' ],
  type: 'log',
  data: {
    qInit:  gql`
      query LogMany($limit:Int!) {
        logMany(limit:$limit) {
          _id timestamp level message pid service source
        }
      }
    `,
    nInit: 'logMany',
    nInitq: 'LogMany',
    qSub: gql`
      subscription LogManySubscription {
        logManySubscription {
          timestamp message level message pid service source
        }
      }
    `,
    nSub: 'logManySubscription',
    length: 60 //TODO base this dynamically on the size of the window
  }
};

export default log;
