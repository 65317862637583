import { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react';
import { Spin } from 'antd';
import axios from 'axios';
import * as d3 from 'd3';
import { ContentContext } from '../../App';

function build_chart(raw_data, width, height) {
  const svg = d3.create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [-width / 2, -height / 2, width, height])
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

  const innerRadius = Math.min(width, height) / 3; // inner radius of pie, in pixels (non-zero for donut)
  const outerRadius = Math.min(width, height) / 2; // outer radius of pie, in pixels
  const labelRadius = (innerRadius + outerRadius) / 2; // center radius of labels
  const stroke = innerRadius > 0 ? 'none' : 'white'; // stroke separating widths
  const strokeWidth = 1; // width of stroke separating wedges
  const strokeLinejoin = 'round'; // line join of stroke separating wedges
  const padAngle = stroke === 'none' ? 1 / outerRadius : 0; // angular separation between wedges

  const data = raw_data.map(d => ({
    name: d.name,
    value: Math.round((d.value.reduce((a, c) => a + c.duration, 0) / d.value.length) / 600) / 100
  })).sort((a, b) => b.value - a.value);

  const N = d3.map(data, d => d.name);
  const V = d3.map(data, d => d.value);
  const I = d3.range(N.length).filter(i => !isNaN(V[i]));

  const names = new d3.InternSet(N);
  
  const colors = d3.schemeSpectral[names.size] || d3.quantize(t => d3.interpolateSpectral(t * 0.8 + 0.1), names.size);
  const color = d3.scaleOrdinal(names, colors);

  const formatValue = d3.format(',');
  const title = i => `${N[i]}\n${formatValue(V[i])}`;

  const arcs = d3.pie().padAngle(padAngle).sort(null).value(i => V[i])(I);
  const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);
  const arcLabel = d3.arc().innerRadius(labelRadius).outerRadius(labelRadius);

  svg.append('text')
    .attr('text-anchor', 'middle')
    .text(`Avg. Total ${Math.round(data.reduce((a, c) => a + c.value, 0) * 100) / 100} min `);

  svg.append('g')
    .attr('stroke', stroke)
    .attr('stroke-width', strokeWidth)
    .attr('stroke-linejoin', strokeLinejoin)
    .selectAll('path')
    .data(arcs)
    .join('path')
    .attr('fill', d => color(N[d.data]))
    .attr('d', arc)
    .append('title')
    .text(d => title(d.data));

  svg.append('g')
    .attr('font-family', 'sans-serif')
    .attr('font-size', 10)
    .attr('text-anchor', 'middle')
    .selectAll('text')
    .data(arcs)
    .join('text')
    .attr('transform', d => `translate(${arcLabel.centroid(d)})`)
    .selectAll('tspan')
    .data(d => {
      const lines = `${title(d.data)}`.split(/\n/);
      return (d.endAngle - d.startAngle) > 0.25 ? lines : lines.slice(0, 1);
    })
    .join('tspan')
    .attr('x', 0)
    .attr('y', (_, i) => `${i * 1.1}em`)
    .attr('font-weight', (_, i) => i ? null : 'bold')
    .attr('fill', 'black')
    .text(d => `${d} min`);
    
  return svg.node();
}

function Chart(props) {
  const content_context = useContext(ContentContext);
  const ref = useRef(null);
  useLayoutEffect(() => {
    ref.current.replaceChildren();
    ref.current.appendChild(build_chart(props.data, content_context.contentOffsets.width - 24 - 24, content_context.contentOffsets.height - 24 - 24));
    return () => ref.current.replaceChildren();
  }, [props.data]);
  return <div ref={ref} style={{ width: '100%', height: '100%' }}/>;
}


export function MMVAverages() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get('/analytics/mmv/mmv_stats')
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <Spin />;
  if (error) return <div>{JSON.stringify(error)}</div>;
  return <Chart data={data} />;
}