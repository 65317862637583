import React from 'react';
import { gql } from '@apollo/client';
import { Table } from 'antd';
import { DatabaseOutlined } from '@ant-design/icons';
import hasProp from '../../helpers/hasProp';

const expandedRowRender = record => (
  <Table rowKey={r => r.name} columns={[
    {title:'Column',dataIndex:'name'},
    {title:'Nullable',dataIndex:'is_nullable'},
    {title:'Data Type',dataIndex:'data_type'},
    {title:'Char Max Len',dataIndex:'character_maximum_length'},
    {title:'Precision',dataIndex:'numeric_precsion'},
    {title:'Radix',dataIndex:'numeric_precision_radix'},
    {title:'Scale',dataIndex:'numeric_scale'},
    {title:'Precision DateTime',dataIndex:'datetime_precision'}
  ]} dataSource={record.column} size='small'scroll={{ y: 240 }} pagination={false} bordered />
);
expandedRowRender.displayName = 'Details';

const sf_table = {
  title: 'Snowflake Tables',
  icon: <DatabaseOutlined />,
  roles: [ 'Admin' ],
  type: 'table',
  data: {
    bordered: true,
    query: gql`
      query SfTablePagination($current:Int!,$pageSize:Int!) {
        sfTablePagination(current:$current,pageSize:$pageSize) {
          current
          pageSize
          total
          data {
            _id table_schema table_name comment strategy_source_table strategy_shift_field strategy_mtime_field strategy_delete_strategy
            column {
              name is_nullable data_type character_maximum_length numeric_precision numeric_precision_radix numeric_scale datetime_precision
            }
          }
        }
      }
    `,
    nData: 'sfTablePagination',
    columns: [
      {title:'Schema',dataIndex:'table_schema',width:150},
      {title:'Table',dataIndex:'table_name',width:400},
      {title:'Comment',dataIndex:'comment',ellipsis:true},
      {title:'Strategy',children:[
        {title:'Source',dataIndex:'strategy_source_table',ellipsis:true},
        {title:'Shift Field',dataIndex:'strategy_shift_field',width:100},
        {title:'MTime Field',dataIndex:'strategy_mtime_field',width:100},
        {title:'Delete Strategy',dataIndex:'strategy_delete_strategy'}
      ]}
    ],
    rowExpandable: (record) => hasProp(record,'column') && record.column.length > 0,
    expandedRowRender
  }
};

export default sf_table;
