import { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react';
import { Spin } from 'antd';
import axios from 'axios';
import * as d3 from 'd3';
import { ContentContext } from '../../App';


const margin = { left: 40, bottom: 30, right: 180, top: 10 };

const last = array => array[array.length - 1];

function build_chart(raw_data, width, height) {

  const color = d3.scaleOrdinal(d3.schemeCategory10);

  let min_duration = Infinity;
  let max_duration = 0;

  let min_timestamp = Infinity;
  let max_timestamp = 0;

  const lineData = raw_data.map((d) => {
    return {
      key: d.name,
      color: color(d.name),
      data: d.value.map(e => {
        const timestamp = new Date(e.timestamp);
        const duration = e.duration / 60000;
        if (timestamp > max_timestamp) max_timestamp = timestamp;
        if (timestamp < min_timestamp) min_timestamp = timestamp;
        if (duration > max_duration) max_duration = duration;
        if (duration < min_duration) min_duration = duration;
        return {
          date: timestamp,
          value: duration
        };
      }).sort((a, b) => a.date - b.date)
    };
  });

  const xScale = d3.scaleTime()
    .domain([min_timestamp, max_timestamp])
    .range([0, width - margin.left - margin.right]);
  const yScale = d3.scaleLinear()
    .domain([min_duration, max_duration])
    .range([height - margin.top - margin.bottom, 0]);

  const lineGenerator = d3.line()
    .x(d => xScale(d.date))
    .y(d => yScale(d.value));

  const svg = d3.create('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', [0, 0, width, height])
    .attr('style', 'max-width: 100%; height: auto; height: intrinsic;');

  const g = svg.append('g')
    .attr('transform', `translate(${margin.left}, ${margin.top})`);

  g.append('g')
    .call(d3.axisBottom(xScale))
    .attr('transform', `translate(0, ${height - margin.bottom - margin.top})`);

  g.append('g')
    .call(d3.axisLeft(yScale));

  g.selectAll('.line')
    .data(lineData)
    .enter().append('path')
    .attr('d', d => lineGenerator(d.data))
    .style('fill', 'none')
    .style('stroke', d => d.color)
    .style('stroke-width', 2)
    .style('stroke-linejoin', 'round');

  const valueLabel = g.selectAll('.label')
    .data(lineData)
    .enter().append('g')
    .attr('transform', d => `translate(${xScale(last(d.data).date)}, ${yScale(last(d.data).value)})`);

  valueLabel.append('circle')
    .attr('r', 4)
    .style('stroke', 'white')
    .style('fill', d => d.color);

  valueLabel.append('text')
    .text(d => d.key)
    .attr('dy', 5)
    .attr('dx', 10)
    .style('font-family', 'monospace')
    .style('fill', d => d.color);

  return svg.node();
}

function Chart(props) {
  const content_context = useContext(ContentContext);
  const ref = useRef(null);
  useLayoutEffect(() => {
    ref.current.replaceChildren();
    ref.current.appendChild(build_chart(props.data, content_context.contentOffsets.width - 24 - 24, content_context.contentOffsets.height - 24 - 24));
    return () => ref.current.replaceChildren();
  }, [props.data]);
  return <div ref={ref} style={{ width: '100%', height: '100%' }} />;
}


export function MMVTimeline() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios.get('/analytics/mmv/mmv_stats')
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <Spin />;
  if (error) return <div>{JSON.stringify(error)}</div>;
  return <Chart data={data} />;
}