import { UserRoleEnum } from '@mts/user-core';
import { PieChartOutlined, LineChartOutlined } from '@ant-design/icons';
import { MMVAverages } from './MMVAverages';
import { MMVTimeline } from './MMVTimeline';

export const analytics = {
  type: 'group',
  title: 'Analytics',
  roles: UserRoleEnum,
  children: {
    mmv_avg: {
      title: 'MMV Averages',
      key: 'mmv_avg',
      icon: <PieChartOutlined />,
      type: 'custom',
      custom: <MMVAverages />
    },
    mmv_timeline: {
      title: 'MMV Timeline',
      key: 'mmv_timeline',
      icon: <LineChartOutlined />,
      type: 'custom',
      custom: <MMVTimeline />
    }
  }
};