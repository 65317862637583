import React from 'react';
import { gql } from '@apollo/client';
import { GlobalOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import filterable from '../../components/Filterable';
import filterableRegex from '../../components/FilterableRegex';

const renderBoolean = b => (
  <Checkbox disabled="true" checked={b} />
);

const filterQuery = gql`
query SfAssetFilters($column: String) {
  sfAssetFilters(column: $column) {
    type,
    filters { key, value }
  }
}
`;
const filterIndex = 'sfAssetFilters';

const sf_asset = {
  title: 'Asset IDs',
  icon: <GlobalOutlined />,
  type: 'table',
  roles: [ 'Admin' ],
  data: {
    query:  gql`
      query SfAssetPagination($current:Int!,$pageSize:Int!,$filters:SfAssetFilters,$sorters:Sorters) {
        sfAssetPagination(current:$current,pageSize:$pageSize,filters:$filters,sorters:$sorters) {
          current
          pageSize
          total
          data {
            _id asset_id client_initials mine_asset_id client_name modular_region data_retain_days site_language gps_transform ingest
          }
        }
      }
    `,
    nData: 'sfAssetPagination',
    columns: [
      {title:'Asset ID',dataIndex:'asset_id', ...filterableRegex()},
      {title:'Client Intls',dataIndex:'client_initials'},
      {title:'Mine Asset ID',dataIndex:'mine_asset_id'},
      {title:'Client',dataIndex:'client_name', ...filterableRegex()},
      {title:'Region',dataIndex:'modular_region', ...filterable(filterQuery, filterIndex, 'modular_region')},
      {title:'Days Retention',dataIndex:'data_retain_days',align:'right'},
      {title:'Site Language',dataIndex:'site_language', ...filterable(filterQuery, filterIndex, 'site_language')},
      {title:'Ingest?',dataIndex:'ingest',render:renderBoolean,align:'center'},
      {title:'GPS Transform',dataIndex:'gps_transform'}
    ]
  }
};

export default sf_asset;
